// extracted by mini-css-extract-plugin
export var articleWrapper = "j_m4";
export var articleText = "j_m5 f_fd f_db";
export var header = "j_m6 f_bM f_bj";
export var headerImageWrapper = "j_m7 f_b1 f_bN f_bX f_bW f_bS f_bY f_b3 f_cz";
export var headerGradient = "j_m8 f_b1 f_bN f_bX f_bW f_bS f_bY";
export var headerGradientOverlay = "j_m9 f_b1 f_bN f_bX f_bW f_bS f_bY";
export var headerContentWrapper = "j_nb f_cj f_cx f_cm f_cr f_b4 f_bM";
export var headerContentWrapperAlt = "j_nc f_cj f_cx f_cm f_cr f_b4 f_bM";
export var contentWrapper = "j_mY f_bj";
export var dateTag = "j_nd f_cZ f_c9 f_d8 f_bj";
export var icon1 = "j_nf f_dx";
export var icon2 = "j_ng f_dx f_dr";
export var tagBtn = "j_nh f_cL f_dm f_d7 f_fk";
export var headerText = "j_nj f_bj f_cZ f_db f_cF";
export var center = "j_nk f_ff";
export var videoIframeStyle = "j_nl f_fL f_bj f_bt f_ch f_cL f_bD";
export var articleImageWrapper = "j_nm f_db f_bM";
export var articleImageWrapperIcon = "j_nn f_db f_bM";
export var articleRow = "j_np f_cm f_j";
export var quoteWrapper = "j_hr f_cl f_cx f_db";
export var quoteBar = "j_nq f_bt";
export var quoteText = "j_nr";
export var authorBox = "j_ns f_bj";
export var authorRow = "j_nt f_cm f_cF f_dh";
export var separator = "j_nv f_bj";
export var line = "j_fZ f_bj f_fZ f_dH f_dh";
export var authorImage = "j_lV f_cS f_bM f_bj f_dc";
export var authorText = "j_nw f_c1 f_dc";
export var masonryImageWrapper = "j_nx";
export var bottomSeparator = "j_ny f_bj f_dh";
export var linksWrapper = "j_nz f_c1 f_fd";
export var comments = "j_nB f_cm f_dh";
export var sharing = "j_nC f_cj f_cm f_cr";
export var shareText = "j_nD f_bj f_ff";
export var icon = "j_nF";
export var text = "j_nG";
export var SubtitleSmall = "j_nH";
export var SubtitleNormal = "j_nJ";
export var SubtitleLarge = "j_nK";