// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "F_vw f_ht f_df f_fd";
export var quoteParagraphCenter = "F_hv f_hv f_df f_ff";
export var quoteParagraphRight = "F_hw f_hw f_df f_fg";
export var quoteRowLeft = "F_vx f_cp";
export var quoteRowCenter = "F_vy f_cm";
export var quoteRowRight = "F_vz f_cq";
export var quoteWrapper = "F_hr f_hr f_bj f_dm f_cj f_cx f_cm";
export var quoteContentWrapper = "F_hs f_hs";
export var quoteExceptionSmall = "F_vc D_vc";
export var quoteExceptionNormal = "F_vd D_vd";
export var quoteExceptionLarge = "F_vf D_vf";
export var quoteAuthorExceptionSmall = "F_vg D_vg";
export var quoteAuthorExceptionNormal = "F_vh D_vh";
export var quoteAuthorExceptionLarge = "F_vj D_vj";