// extracted by mini-css-extract-plugin
export var noMargin = "r_rb f_cX";
export var carouselCol = "r_rc f_dm";
export var carouselWrapper = "r_lG f_lG f_cj f_cm f_cx f_bj f_bt";
export var carouselImageWrapper = "r_lH f_lH f_bt f_bj";
export var carouselContainer = "r_rd f_dm";
export var carouselContainerFull = "r_rf f_fy";
export var carouselImg = "r_y f_x f_bj f_bt f_cz";
export var imageTextWrapperLeft = "r_lz f_lz f_ly f_bN f_cC";
export var imageTextWrapperCenter = "r_lB f_lB f_ly f_bN f_cC";
export var imageTextWrapperRight = "r_lC f_lC f_ly f_bN f_cC";
export var imageTextWrapperHidden = "r_lx f_lx";
export var imageAltSmall = "r_rg f_lD";
export var imageAltNormal = "r_rh f_lD";
export var imageAltLarge = "r_rj f_lD";
export var imageTextSmall = "r_rk f_lF";
export var imageTextNormal = "r_rl f_lF";
export var imageTextLarge = "r_rm f_lF";