// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "G_vB f_cm";
export var storyRowWrapper = "G_h7 f_h7 f_cs";
export var storyLeftWrapper = "G_vC f_cj f_cx";
export var storyWrapperFull = "G_vD f_dm";
export var storyWrapperFullLeft = "G_pv f_dm f_cj f_cx f_cm";
export var contentWrapper = "G_mY f_h8";
export var storyLeftWrapperCenter = "G_vF f_h9 f_cj f_cm f_cx f_cr";
export var storyRightWrapperCenter = "G_vG f_jf";
export var storyHeader = "G_vH f_jd f_bj f_db";
export var storyHeaderCenter = "G_jc f_jc f_bj f_db f_ff f_ch";
export var storyParagraphCenter = "G_jb f_jb f_ch f_ff";
export var storyBtnWrapper = "G_vJ f_fH f_fG f_bj f_cj f_cp";
export var storyBtnWrapperCenter = "G_jh f_jh f_fG f_bj f_cj f_cm";
export var imageWrapper = "G_qz f_fW f_bM";
export var imageWrapperFull = "G_vK f_bj f_bt f_j f_bM";