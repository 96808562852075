// extracted by mini-css-extract-plugin
export var articleWrapper = "q_m4";
export var searchResult = "q_qL q_m4";
export var line = "q_fZ f_fZ f_dH";
export var articleTitle = "q_qM f_c8";
export var articleText = "q_m5 f_db";
export var authorImage = "q_lV f_lV f_cS f_bM f_dc";
export var authorText = "q_nw f_c1 f_dc";
export var textCenter = "q_ff f_ff";
export var searchWrapper = "q_qN f_cj f_cx f_d9 f_c4 f_bM";
export var searchInput = "q_qP f_dn f_bj f_cP";
export var searchBtn = "q_qQ f_cL f_cl f_cx f_bN f_dm f_fk";
export var text = "q_nG f_c9";
export var elementWrapper = "q_mW f_c4";
export var select = "q_qR f_bj f_cP";
export var articleImageWrapper = "q_nm f_db f_bM f_bH";
export var articleImageWrapperIcon = "q_nn f_db f_bM f_bH";
export var articleImageWrapperColumns = "q_qS f_db f_fX f_bM";
export var contentPadding = "q_qT f_dB";
export var otherPadding = "q_qV f_dv";
export var paginationWrapper = "q_qW";
export var pagination = "q_qX";
export var disabledPagination = "q_qY";
export var breakMe = "q_qZ";
export var activePage = "q_q0";
export var next = "q_q1";
export var pages = "q_q2";
export var searchTitle = "q_q3 f_c9";
export var categoryTitle = "q_q4 f_c9 f_bM";
export var searchText = "q_q5 f_dc";
export var bold = "q_q6";
export var field = "q_q7";
export var dateTag = "q_nd f_7 f_c9 f_d8";
export var icon1 = "q_nf f_dx";
export var icon2 = "q_ng f_dx f_dr";
export var tagBtn = "q_nh f_cL f_dm f_d7 f_fk";
export var linksWrapper = "q_nz f_c1 f_ff";
export var header = "q_m6 f_bj f_bM";
export var headerImage = "q_q8 f_b1 f_bN f_bX f_bW f_bS f_bY";
export var separator = "q_nv f_dH";
export var resultImage = "q_q9";