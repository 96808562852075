// extracted by mini-css-extract-plugin
export var lbContainerOuter = "l_pL";
export var lbContainerInner = "l_pM";
export var movingForwards = "l_pN";
export var movingForwardsOther = "l_pP";
export var movingBackwards = "l_pQ";
export var movingBackwardsOther = "l_pR";
export var lbImage = "l_pS";
export var lbOtherImage = "l_pT";
export var prevButton = "l_pV";
export var nextButton = "l_pW";
export var closing = "l_pX";
export var appear = "l_pY";