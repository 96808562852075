// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_sr f_g7 f_db";
export var heroHeaderCenter = "x_g8 f_g8 f_db f_ff";
export var heroHeaderRight = "x_g9 f_g9 f_db f_fg";
export var heroParagraphLeft = "x_ss f_g4 f_df";
export var heroParagraphCenter = "x_g5 f_g5 f_df f_ff";
export var heroParagraphRight = "x_g6 f_g6 f_df f_fg";
export var heroBtnWrapperLeft = "x_st f_fH f_fG f_bj f_cj f_cp";
export var heroBtnWrapperCenter = "x_sv f_fJ f_fG f_bj f_cj f_cm";
export var heroBtnWrapperRight = "x_sw f_fK f_fG f_bj f_cj f_cq";
export var overlayBtnWrapper = "x_sx f_g3 f_bN f_bW f_bX f_bY f_b6 f_cX";
export var design4 = "x_sy f_g2 f_bN f_bW f_bX f_b6";
export var heroExceptionSmall = "x_sz D_sz";
export var heroExceptionNormal = "x_sB D_sB";
export var heroExceptionLarge = "x_sC D_sC";
export var Title1Small = "x_sD D_sD D_tr D_ts";
export var Title1Normal = "x_sF D_sF D_tr D_tt";
export var Title1Large = "x_sG D_sG D_tr D_tv";
export var BodySmall = "x_sH D_sH D_tr D_tL";
export var BodyNormal = "x_sJ D_sJ D_tr D_tM";
export var BodyLarge = "x_sK D_sK D_tr D_tN";