// extracted by mini-css-extract-plugin
export var alignDiscLeft = "w_rX f_f3 f_cp f_fd";
export var alignLeft = "w_p0 f_f3 f_cp f_fd";
export var alignDiscCenter = "w_rY f_f4 f_cm f_ff";
export var alignCenter = "w_cx f_f4 f_cm f_ff";
export var alignDiscRight = "w_rZ f_f5 f_cq f_fg";
export var alignRight = "w_p1 f_f5 f_cq f_fg";
export var footerContainer = "w_r0 f_fB f_cF";
export var footerContainerFull = "w_r1 f_fy f_cF";
export var footerHeader = "w_kK f_kK";
export var footerTextWrapper = "w_r2 f_bj";
export var footerDisclaimerWrapper = "w_kQ f_kQ f_c3";
export var badgeWrapper = "w_r3 f_bj f_cj f_cx f_cn f_cr";
export var footerDisclaimerRight = "w_kR f_kR f_cj";
export var footerDisclaimerLeft = "w_kS f_kS f_cj";
export var verticalTop = "w_r4 f_cj f_cr f_cw f_ct";
export var firstWide = "w_r5";
export var disclaimer = "w_r6";
export var socialDisclaimer = "w_r7";
export var left = "w_r8";
export var wide = "w_r9 f_dh";
export var right = "w_sb f_cs";
export var line = "w_fZ f_f0 f_dd";
export var badgeDisclaimer = "w_sc f_cl f_cx f_cr";
export var badgeContainer = "w_sd f_cj f_cq f_cx";
export var badge = "w_sf";
export var padding = "w_sg f_dS";
export var end = "w_sh f_cq";
export var linkWrapper = "w_sj f_fk";
export var link = "w_m0 f_fk";
export var colWrapper = "w_sk f_dg";
export var media = "w_sl f_cg f_fh";
export var itemRight = "w_sm";
export var itemLeft = "w_sn";
export var itemCenter = "w_sp";
export var exceptionWeight = "w_sq D_tT";