// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "y_sr f_g7 f_db";
export var heroHeaderCenter = "y_g8 f_g8 f_db f_ff";
export var heroHeaderRight = "y_g9 f_g9 f_db f_fg";
export var heroParagraphLeft = "y_ss f_g4 f_df";
export var heroParagraphCenter = "y_g5 f_g5 f_df f_ff";
export var heroParagraphRight = "y_g6 f_g6 f_df f_fg";
export var heroSliderBtnWrapperLeft = "y_sL f_fH f_fG f_bj f_cj f_cp f_cr";
export var heroSliderBtnWrapperCenter = "y_sM f_fJ f_fG f_bj f_cj f_cm f_cr";
export var heroSliderBtnWrapperRight = "y_sN f_fK f_fG f_bj f_cj f_cq f_cr";
export var btnWrapper = "y_fH";
export var wrapper = "y_pZ f_cj f_cx f_bj f_bt";
export var background = "y_sP f_b1 f_bN f_bX f_bW f_bS f_bY";
export var frameWrapper = "y_sQ f_bj f_bt f_bM f_bS f_bW f_b3 f_j f_cd";
export var videoFrame = "y_sR f_bN";
export var videoOverlay = "y_sS f_bj f_bt f_bN f_bS";
export var arrow = "y_ld f_bN f_9";
export var arrowLeft = "y_sT y_ld f_bN f_9";
export var arrowRight = "y_sV y_ld f_bN f_9";
export var buttonsWrapper = "y_sW f_cj f_cx";
export var dotsWrapper = "y_sX f_cj f_cx f_cm f_bN";
export var dot = "y_sY f_cg f_fk";
export var dotActive = "y_sZ y_sY f_cg f_fk";
export var heroSliderBackgroundImage = "y_4 f_bj f_bt f_cz f_b3";
export var loadingWrapper = "y_s0 f_b1 f_bN f_bX f_bW f_bS f_bY";
export var topAlt = "y_hd f_hd f_hc f_cj f_cx f_bj";
export var top = "y_hf f_hf f_hc f_cj f_cx f_bj";
export var bottomAlt = "y_hh f_hh f_b1 f_bN f_bX f_bW f_bS f_bY f_hc f_cj f_cx";
export var bottom = "y_hj f_hj f_b1 f_bN f_bX f_bW f_bS f_bY f_hc f_cj f_cx";
export var heroExceptionSmall = "y_sz D_sz";
export var heroExceptionNormal = "y_sB D_sB";
export var heroExceptionLarge = "y_sC D_sC";