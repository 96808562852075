// extracted by mini-css-extract-plugin
export var customText = "t_rC f_fd f_db f_c0";
export var videoIframeStyle = "t_nl f_fL f_bj f_bt f_ch f_cL f_bD";
export var customImageWrapper = "t_rD f_db f_c0 f_bM";
export var customRow = "t_rF f_cm f_j";
export var quoteWrapper = "t_hr f_cl f_cx f_db f_c0";
export var quoteBar = "t_nq f_bt";
export var masonryImageWrapper = "t_nx";
export var title = "t_rG";
export var Title3Small = "t_rH D_rH D_tr D_tz";
export var Title3Normal = "t_rJ D_rJ D_tr D_tB";
export var Title3Large = "t_rK D_rK D_tr D_tC";